<template>
  <div>
    <b-card title="Teklif Talebi">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Firma Adı
          </div>
          <div>{{ dataItem.company }}</div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Talep Eden
          </div>
          <div>{{ dataItem.customer_account }}</div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="font-weight-bold text-primary">
            Talep Edilen Temsilci
          </div>
          <div>{{ dataItem.username }}</div>
        </b-col>
        <b-col class="mt-2">
          <div class="font-weight-bold text-primary">
            Durum
          </div>
          <div>
            <span v-if="dataItem.sent">
              {{ moment(dataItem.sent).format('LLLL') }} tarihinde gönderildi.
              <div
                v-if="!dataItem.id_offers"
                class="text-info font-small-2"
              >
                Talep Beklemede
              </div>
              <div
                v-else
                class="text-success font-small-2"
              >
                Teklif Oluşturuldu
              </div>
            </span>
            <span
              v-else
              class="text-info"
            >
              Talep hazırlanıyor
            </span>
          </div>
        </b-col>
        <b-col
          v-if="dataItem.notes"
          cols="12"
          class="mt-2"
        >
          <div class="font-weight-bold text-primary">
            Müşteri Notu
          </div>
          <div>{{ dataItem.notes }}</div>
        </b-col>
      </b-row>
      <hr>
      <div class="mt-1">
        <b-button
          v-if="!dataItem.id_offers"
          variant="primary"
          :disabled="!dataItem.sent"
          :to="'/app/offers/request-add/' + dataItem.id"
        >
          <FeatherIcon icon="PlusCircleIcon" />
          Teklif Oluştur
        </b-button>
        <b-button
          v-else
          variant="success"
          :to="'/app/offers/view/' + dataItem.id_offers"
        >
          <FeatherIcon icon="EyeIcon" />
          Teklifi Görüntüle
        </b-button>
      </div>
    </b-card>
    <b-card title="Talep İçeriği">
      <b-row>
        <b-col
          v-for="item in dataItem.items"
          :key="item.id"
          cols="12"
          md="3"
          class="mb-3"
        >
          <div class="border rounded p-1 text-center">
            <div class="overflow-hidden rounded">
              <b-img
                v-if="item.image"
                :src="baseURL + '/media/products/' + item.image"
                fluid-grow
              />
              <empty-image v-else />
            </div>
            <div class="font-weight-bold text-primary mt-1">
              {{ item.product }}
            </div>
            <div class="text-muted">
              {{ item.product_quality }}
            </div>
            <div>{{ item.price | toCurrency }} {{ item.currency }}</div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

export default {
  name: 'RequestView',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    EmptyImage,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['baskets/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('baskets/getDataView', this.$route.params.id)
    },
  },
}
</script>
